import { Check, Warning, X } from '@phosphor-icons/react';
import { Divider, Typography } from '@remarkable/ark-web';
import { getRouteApi, Navigate } from '@tanstack/react-router';

import {
  useEnterpriseEnrollmentStatus,
  useEnterpriseEnrollmentVerify as useEnterpriseVerifyDomain,
} from 'src/api/queries/enterpriseEnrollment';
import {
  Button,
  NotificationBox,
  Sheet,
  Table,
  TableCell,
  TableHeader,
} from 'src/components';
import { Abbreviation } from 'src/components/Abbreviation';
import { AppPage } from 'src/components/AppPage';
import { CopyText } from 'src/components/CopyText';
import { URLS } from 'src/utils/urls/urls';

import { CancelSetupModal } from '../components/CancelSetup';
import { EnterpriseSteps } from '../components/EnterpriseSteps';

const routeApi = getRouteApi('/_auth/_layout/enterprise/enroll/verify-domain');

export function VerifyDomainPage() {
  const enrollmentStatus = useEnterpriseEnrollmentStatus();
  const verifyDomain = useEnterpriseVerifyDomain();
  const domain = enrollmentStatus.data?.primaryDomain;
  const navigate = routeApi.useNavigate();

  if (!enrollmentStatus.data?.primaryDomain) {
    // User has not initiated the enrollment yet
    return <Navigate to=".." />;
  }

  const DomainStatus = (() => {
    switch (enrollmentStatus.data?.domainVerification) {
      case 'OK':
        return (
          <div className="flex items-center gap-4 text-pen-blue">
            <Check className="" size={24} />
            <Typography variant="body-md-regular">Verified</Typography>
          </div>
        );
      case 'INVALID':
        return (
          <div className="flex items-center gap-4">
            <Warning className="text-feedback-orange-500" size={24} />
            <Typography variant="body-md-regular">Invalid</Typography>
          </div>
        );
      case 'HOST_UNREACHABLE':
        return (
          <div className="flex items-center gap-4">
            <Warning className="text-feedback-orange-500" size={24} />
            <Typography variant="body-md-regular">Host unreachable</Typography>
          </div>
        );
      case 'IDLE':
      default:
        return (
          <div className="flex items-center gap-4">
            <X className="text-neutral-dark-1" size={24} />
            <Typography variant="body-md-regular">Not verified</Typography>
          </div>
        );
    }
  })();

  return (
    <AppPage.Container>
      <AppPage.Header title="Single sign-on setup" />
      <AppPage.Content className="max-w-ll">
        <EnterpriseSteps />

        <Sheet className="gap-24">
          <Typography as="h2" variant="heading-sm">
            Verify your domain
          </Typography>

          <Typography variant="body-md-regular">
            Before you can set up{' '}
            <Abbreviation title="Single sign-on">SSO</Abbreviation>, you&apos;ll
            need to verify ownership of your domain. This ensures that only
            authorized members of your organization can log in with{' '}
            <Abbreviation title="Single sign-on">SSO</Abbreviation>, keeping
            your data secure.
          </Typography>

          <Divider />

          <div className="flex flex-col">
            <Typography variant="body-md-bold">
              Add this TXT record in your DNS settings
            </Typography>

            <Typography variant="body-sm-regular">
              Copy this code and paste it into your domain&apos;s{' '}
              <Abbreviation title="Domain Name System">DNS</Abbreviation>{' '}
              settings.
            </Typography>

            <CopyText
              value={enrollmentStatus.data?.dnsTextRecord}
              aria-label="Copy DNS TXT record"
            />
          </div>

          <div className="flex flex-col gap-8">
            <Typography variant="body-md-bold">
              Values for the DNS record
            </Typography>

            <Table className=" border-separate border-spacing-0 overflow-hidden rounded border border-neutral-light-8">
              <colgroup>
                <col className="w-1/3" />
                <col className="w-2/3" />
              </colgroup>
              <thead className="bg-neutral-light-3">
                <tr>
                  <TableHeader>
                    <Typography variant="interface-sm-semibold">
                      Field
                    </Typography>
                  </TableHeader>
                  <TableHeader>
                    <Typography variant="interface-sm-semibold">
                      Value
                    </Typography>
                  </TableHeader>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <TableCell>Record type</TableCell>
                  <TableCell>TXT</TableCell>
                </tr>
                <tr>
                  <TableCell>Value</TableCell>
                  <TableCell truncate={false} className="py-8">
                    {enrollmentStatus.data?.dnsTextRecord}
                  </TableCell>
                </tr>
                <tr>
                  <TableCell>Name / Host / Alias</TableCell>
                  <TableCell truncate={false} className="text-pretty py-8">
                    Use the default value, type @ (at) or leave it blank
                  </TableCell>
                </tr>
                <tr>
                  <TableCell className="border-b-0">
                    <Abbreviation title="Time to live — controls how long the record is cached in seconds">
                      TTL
                    </Abbreviation>
                  </TableCell>
                  <TableCell
                    truncate={false}
                    className="text-pretty border-b-0 py-8"
                  >
                    Type your required period of time, for example{' '}
                    <code className="rounded bg-neutral-light-3 px-4 py-2">
                      3600
                    </code>{' '}
                    (1 hour)
                  </TableCell>
                </tr>
              </tbody>
            </Table>
          </div>

          <div className="flex flex-col gap-8">
            <Typography variant="body-md-bold">Domain</Typography>

            <div className="flex justify-between gap-16 border px-16 py-12">
              <Typography variant="body-md-regular">{domain}</Typography>

              {DomainStatus}
            </div>
          </div>

          {verifyDomain.isSuccess &&
            (enrollmentStatus.data.domainVerification === 'IDLE' ||
              enrollmentStatus.data.domainVerification === 'IN_PROGRESS') && (
              <NotificationBox
                variant="info"
                title="This could take a while"
                data-cy="propagation-time-notification"
              >
                <Typography variant="body-md-regular">
                  It typically takes a few minutes for the record to propagate
                  through the DNS system and for reMarkable to confirm the
                  changes. In some cases, it can take up to 24 hours. Please try
                  again later if it&apos;s not ready yet.
                </Typography>
              </NotificationBox>
            )}

          <div className="flex w-full flex-col-reverse flex-wrap-reverse gap-24 lm:flex-row">
            <div className="flex flex-1 ls:justify-center lm:justify-start">
              <CancelSetupModal
                trigger={
                  <Button
                    variant="tertiary-neutral"
                    className="w-full ls:w-fit"
                  >
                    Cancel
                  </Button>
                }
              />
            </div>

            <div className="flex flex-col-reverse flex-wrap-reverse gap-24 ls:flex-row">
              <Button
                as="a"
                variant="secondary"
                to={URLS.ENTERPRISE_CREATE_ORGANIZATION}
                className="w-full ls:w-fit ls:flex-1 lm:flex-initial"
              >
                Previous
              </Button>
              <Button
                variant="primary"
                className="w-full whitespace-nowrap ls:w-fit ls:flex-1 lm:flex-initial"
                disabled={verifyDomain.isPending}
                loading={verifyDomain.isPending}
                onClick={() => {
                  if (enrollmentStatus.data?.domainVerification === 'OK') {
                    void navigate({ to: '../setup-saml' });
                    return;
                  }

                  verifyDomain.mutate(undefined, {
                    async onSuccess() {
                      const newStatus = await enrollmentStatus.refetch();

                      if (newStatus.data?.domainVerification === 'OK') {
                        void navigate({ to: '../setup-saml' });
                      }
                    },
                    async onError() {
                      await enrollmentStatus.refetch();
                    },
                  });
                }}
              >
                {enrollmentStatus.data.domainVerification === 'OK'
                  ? 'Continue'
                  : 'Check domain'}
              </Button>
            </div>
          </div>

          <NotificationBox useErrorMessage error={verifyDomain.error} />
        </Sheet>
      </AppPage.Content>
    </AppPage.Container>
  );
}
