import { HTMLAttributes } from 'react';

import { Typography } from '@remarkable/ark-web';

import { ButtonClicked } from 'src/ampli';
import { tracker } from 'src/analytics/tracker';
import { ComponentLocations } from 'src/analytics/trackingTypes';
import { TestId } from 'src/testids';
import { AccountLinkingMismatchError } from 'src/utils/errors';
import { userHelpers } from 'src/utils/userHelpers';

import { Link2 } from './Link2';
import { NotificationBox } from './NotificationBox';

interface Props extends HTMLAttributes<HTMLDivElement> {
  error: AccountLinkingMismatchError;
}

export function ErrorAccountLinkingMismatch({ error, ...props }: Props) {
  const currentUserEmail =
    userHelpers.getEmail(error.currentUser) ||
    userHelpers.getEmailFromClaims(error.currentUser);
  const expectedUserEmail =
    userHelpers.getEmail(error.expectedUser) ||
    userHelpers.getEmailFromClaims(error.expectedUser);

  return (
    <div
      data-cy={TestId.PageEnterpriseEnrollmentAccountLinkingMismatchError}
      {...props}
    >
      <div className="m-auto flex w-full max-w-screen-md flex-col items-center gap-24 px-24 py-64 text-center md:px-64 lg:py-128">
        <Typography variant="heading-lg" className="text-center">
          SSO verification failed
        </Typography>
        <Typography variant="body-md-regular">
          We expected you to log in with{' '}
          <b>{expectedUserEmail || 'unknown email'}</b>, but you were logged in
          as <b>{currentUserEmail || 'unknown email'}</b> instead.
        </Typography>

        <NotificationBox
          variant="info"
          title="Troubleshooting"
          className="mt-32"
        >
          <ul className="flex list-disc flex-col gap-8 pl-24 pt-8">
            <li>
              <Typography variant="body-md-regular">
                <em>
                  If you did not see a log in screen after logging in to SSO
                </em>
                , you might already be in an active SSO session on a different
                account. Sign out of that session first or switch to a
                private/incognito window and try again.
              </Typography>
            </li>
            <li>
              <Typography variant="body-md-regular">
                <em>If you did sign in with the correct email</em>, it might be
                that your identity provider reports a different email address
                for your account. Please verify that the attribute mapping in
                your identity provider reports the correct email address on the{' '}
                <code>email</code> attribute and that the attribute name is an
                exact match.
              </Typography>
            </li>
          </ul>
        </NotificationBox>

        <Link2
          to="/login"
          search={{
            redirect: error.redirect,
            login_hint: expectedUserEmail,
            connection:
              userHelpers.getConnectionFromUserId(error.expectedUser) ??
              undefined,
          }}
          as="button"
          variant="primary"
          className="ark-button ark-button--primary mt-32"
          onClick={() => {
            tracker.trackEvent(
              new ButtonClicked({
                component_location:
                  ComponentLocations.ENTERPRISE.ACCOUNT_LINKING_MISMATCH,
                action: 'sign in to previous account',
                text: 'Sign back in',
              })
            );
          }}
        >
          Sign back in
        </Link2>
      </div>
    </div>
  );
}
