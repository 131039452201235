import { paths } from 'apis/cloud-enterprise-policy-api';
import createClient from 'openapi-fetch';

import { CONFIG } from 'src/config';

import { addAuthHeader } from './middleware/addAuthHeader';
import { throwOnErrorResponse } from './middleware/throwOnErrorResponse';

const cloudEnterprisePolicyApiClient = createClient<paths>({
  baseUrl: CONFIG.CloudEnterpriseApiUrl,
});

cloudEnterprisePolicyApiClient.use(addAuthHeader, throwOnErrorResponse);

export { cloudEnterprisePolicyApiClient };
