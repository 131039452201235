/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useMemo, useRef, useState } from 'react';

import { CaretRight, Warning } from '@phosphor-icons/react';
import { Typography } from '@remarkable/ark-web';
import { getRouteApi } from '@tanstack/react-router';
import {
  ColumnDef,
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';
import { useDebouncedCallback } from 'use-debounce';

import { FleetMemberRow } from 'src/api/endpoints/cloudEnterprise.types';
import { useEnterpriseInvitations } from 'src/api/queries/enterpriseInvitations';
import { useFleetMembers } from 'src/api/queries/fleet';
import { Input, SomethingWentWrong, Table, Tooltip } from 'src/components';
import { CopyButton } from 'src/components/CopyButton';
import { DeviceIcon } from 'src/components/DeviceIcon';
import {
  Drawer,
  DrawerContent,
  DrawerPortal,
  DrawerTrigger,
} from 'src/components/Drawer';
import { IntegrationIcon } from 'src/components/IntegrationIcon';
import { TimeSinceNow } from 'src/components/TimeSinceNow';
import { IS_DEVELOPMENT } from 'src/config';
import { useMultiRowSelect } from 'src/utils/createTableSelectColumn';
import { ensureUniqueKeys } from 'src/utils/ensureUniqueKeys';
import { formatShortDate } from 'src/utils/formatDate';
import { sortByDate } from 'src/utils/sortByDate';
import { sortByDeviceLists } from 'src/utils/sortByDeviceLists';
import { sortByIntegrationLists } from 'src/utils/sortByIntegrationLists';
import { useOnClickOutside } from 'src/utils/useOnClickOutside';

import { getDeviceName } from '../utils/getDeviceName';
import { getIntegrationName } from '../utils/getIntegrationName';
import { InviteToEnterpriseButton } from './InviteToEnterpriseButton';
import { MemberActionMenu } from './MemberActionMenu';
import { MemberDrawer } from './MemberDrawer';
import { PoliciesButton } from './PoliciesButton';
import { fleetMemberRowHelpers } from './fleetMemberRowHelpers';

const columnHelper = createColumnHelper<FleetMemberRow>();

const routeApi = getRouteApi(
  '/_auth/_layout/enterprise/_with-org/_layout/members'
);

export const FleetTable = () => {
  const members = useFleetMembers();
  const invitations = useEnterpriseInvitations();

  const memberRows: FleetMemberRow[] = useMemo(() => {
    const mappedInvitations =
      invitations.data?.invitations?.map(
        (i) =>
          ({
            type: 'invitation',
            data: i,
          } as const)
      ) ?? [];
    const mappedUsers =
      members.data?.users?.map((u) => ({ type: 'user', data: u } as const)) ??
      [];

    return [...mappedInvitations, ...mappedUsers];
  }, [members.data, invitations.data]);

  const search = routeApi.useSearch();
  const navigate = routeApi.useNavigate();

  const [globalFilter, setGlobalFilter] = useState('');
  const debouncedGlobalFilter = useDebouncedCallback<typeof setGlobalFilter>(
    (value) => setGlobalFilter(value),
    100
  );

  const tableRef = useRef(null);
  const modalRef = useRef(null);

  useOnClickOutside([tableRef, modalRef], () => {
    if (search.member) {
      void navigate({ search: { member: undefined }, replace: true });
    }
  });

  const columns = useMemo(
    () =>
      [
        // createSelectColumn(columnHelper),
        columnHelper.accessor(
          (d) =>
            fleetMemberRowHelpers.getName(d) +
            fleetMemberRowHelpers.getEmail(d),
          {
            header: 'User',
            meta: {
              truncate: true,
            },
            cell: (info) => {
              const fullName = fleetMemberRowHelpers.getName(info.row.original);
              const hasName = fleetMemberRowHelpers.hasName(info.row.original);
              const isInvitation = fleetMemberRowHelpers.isInvitation(
                info.row.original
              );

              return (
                <div className="w-full">
                  {hasName && (
                    <Typography
                      variant="interface-sm-regular"
                      className="truncate"
                    >
                      {fullName}
                    </Typography>
                  )}
                  <Typography
                    variant={
                      hasName ? 'interface-xs-regular' : 'interface-sm-regular'
                    }
                    className={clsx('mt-6 truncate', {
                      'text-neutral-dark-1': hasName,
                      italic: isInvitation,
                    })}
                  >
                    {fleetMemberRowHelpers.getEmail(info.row.original)}
                  </Typography>
                </div>
              );
            },
          }
        ),
        columnHelper.accessor(
          (d) => fleetMemberRowHelpers.getRoleNames(d).join(', '),
          {
            header: 'Roles',
            meta: {
              truncate: true,
              colWidth: 'w-1/5',
            },
            cell(info) {
              if (!fleetMemberRowHelpers.isInvitation(info.row.original)) {
                return info.getValue();
              }

              return (
                <div className="flex w-full items-center justify-between gap-12">
                  <span>Invited</span>
                  <CopyButton
                    variant="tertiary"
                    size="small"
                    className="inline-flex"
                    value={info.row.original.data.invitationUrl}
                  >
                    Copy link
                  </CopyButton>
                </div>
              );
            },
          }
        ),
        columnHelper.accessor(
          (d) => {
            const issues = fleetMemberRowHelpers.getIssues(d);
            return issues.length > 0 ? issues.join(' ') : undefined;
          },
          {
            header: 'Status',
            id: 'status',
            meta: {
              colWidth: 'w-[80px]',
            },
            cell(info) {
              return info.getValue() ? (
                <Tooltip title={info.getValue()}>
                  <Warning size={20} className="text-feedback-orange-500" />
                </Tooltip>
              ) : (
                '-'
              );
            },
          }
        ),
        columnHelper.accessor(fleetMemberRowHelpers.getLastSeenDate, {
          header: 'Last seen',
          meta: {
            colWidth: 'w-1/6',
          },
          sortingFn: (a, b) => {
            return sortByDate(
              fleetMemberRowHelpers.getLastSeenDate(a.original),
              fleetMemberRowHelpers.getLastSeenDate(b.original)
            );
          },
          cell: (info) => <TimeSinceNow date={info.getValue()} />,
        }),
        columnHelper.accessor(
          (d) => fleetMemberRowHelpers.getIntegrationNames(d).join(', ') || '-',
          {
            header: 'Integrations',
            id: 'integrations',
            enableHiding: true,
            meta: {
              colWidth: 'w-1/6',
            },
            sortingFn: (a, b) => {
              return sortByIntegrationLists(
                fleetMemberRowHelpers.getIntegrations(a.original),
                fleetMemberRowHelpers.getIntegrations(b.original)
              );
            },
            cell: (info) => (
              <ul className="flex gap-4">
                {fleetMemberRowHelpers
                  .getIntegrations(info.row.original)
                  .map((integration) => (
                    <li
                      className="shrink-0"
                      key={
                        (integration.provider ?? 'provider') +
                        (integration.createdAt ?? 'createdAt')
                      }
                    >
                      <Tooltip
                        title={
                          <>
                            {integration.hasIssue && (
                              <Warning
                                size={20}
                                className="mr-4 inline text-feedback-orange-500"
                              />
                            )}
                            <span>
                              {getIntegrationName(integration)} (
                              {formatShortDate(integration.createdAt, {
                                showTime: true,
                                showYear: true,
                              })}
                              )
                              {integration.hasIssue &&
                                ' - Needs reauthorization'}
                            </span>
                          </>
                        }
                      >
                        <div className="relative">
                          {integration.hasIssue && (
                            <span className="absolute right-0 top-0 size-8 rounded-full border border-neutral-light-2 bg-feedback-orange-500"></span>
                          )}
                          <IntegrationIcon
                            className="h-20"
                            provider={integration.provider}
                          />
                        </div>
                      </Tooltip>
                    </li>
                  ))}
              </ul>
            ),
          }
        ),
        columnHelper.accessor(
          (data) =>
            fleetMemberRowHelpers
              .getDevices(data)
              .map((d) =>
                [
                  getDeviceName(d),
                  d.type,
                  d.serialNumber,
                  d.softwareVersion,
                ].join('|')
              )
              .join(', ') || '-',
          {
            header: 'Devices',
            meta: {
              colWidth: 'w-1/5',
            },
            sortingFn: (a, b) => {
              return sortByDeviceLists(
                fleetMemberRowHelpers.getDevices(a.original),
                fleetMemberRowHelpers.getDevices(b.original)
              );
            },
            cell: (info) => (
              <ul className="flex gap-4">
                {ensureUniqueKeys(
                  fleetMemberRowHelpers.getDevices(info.row.original),
                  (d) => d.serialNumber
                ).map((device) => (
                  <li className="shrink-0" key={device.key}>
                    <Tooltip title={getDeviceName(device)} asChild>
                      <span>
                        <DeviceIcon device={device} />
                      </span>
                    </Tooltip>
                  </li>
                ))}
              </ul>
            ),
          }
        ),
        columnHelper.display({
          header: 'Edit',
          meta: {
            align: 'center',
            colWidth: 'w-[50px]',
            truncate: false,
          },
          cell: ({ row }) =>
            fleetMemberRowHelpers.isUser(row.original) ? (
              <MemberActionMenu member={row.original.data} />
            ) : null,
        }),
      ].filter(Boolean),
    []
  );

  const table = useReactTable({
    data: memberRows,
    columns: columns as ColumnDef<FleetMemberRow>[],
    initialState: {
      pagination: {
        pageSize: 25,
      },
      columnVisibility: {
        status: false,
      },
    },
    state: {
      globalFilter,
    },
    meta: {
      ...useMultiRowSelect<FleetMemberRow>(),
      fillRowsToMatchPageSize: false,
    },
    enableFilters: true,
    getRowId: (data, index) => data.data.id || index.toString(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    renderFallbackValue: '-',
  });

  /**
   * Sync member data with the member id. This is used to allow
   * data to stay available for the exit animations.
   */
  const activeMemberData = useMemo(() => {
    return (
      members.data?.users?.find((m) => {
        const test = m.email === search.member;
        return test;
      }) ?? null
    );
  }, [search.member, members.data?.users]);

  if (members.isError) return <SomethingWentWrong />;

  return (
    <div className="flex flex-col gap-24 py-16">
      <div className="flex flex-col items-center justify-between gap-16 ls:flex-row">
        <div className="flex w-full flex-wrap gap-16">
          <Input
            id="search"
            placeholder="Search"
            className="w-full ls:max-w-[300px]"
            onChange={(e) => debouncedGlobalFilter(e.currentTarget.value)}
          />
          {table.getState().globalFilter && (
            <Typography variant="interface-sm-caps">
              {table.getFilteredRowModel().flatRows.length} results
            </Typography>
          )}
        </div>

        <div className="flex items-center gap-16">
          {IS_DEVELOPMENT && <PoliciesButton />}
          <InviteToEnterpriseButton />
        </div>
      </div>

      <Drawer
        open={!!search.member && members.isSuccess}
        modal={false}
        onOpenChange={(open: boolean) => {
          if (!open) {
            void navigate({ search: { member: undefined }, replace: true });
          }
        }}
      >
        <div ref={tableRef} className="w-full">
          <Table
            table={table}
            data-cy="members-table"
            className="hidden w-full lm:table"
            loading={members.isPending}
            pagination
            rowWrapper={({ children, row }) => {
              return (
                <DrawerTrigger
                  asChild
                  type={undefined}
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    if (search.member !== row.original.data.email) {
                      void navigate({
                        search: { member: row.original.data.email },
                        replace: true,
                      });
                    } else {
                      void navigate({
                        search: { member: undefined },
                        replace: true,
                      });
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      if (search.member !== row.original.data.email) {
                        void navigate({
                          search: { member: row.original.data.email },
                          replace: true,
                        });
                      } else {
                        void navigate({
                          search: { member: undefined },
                          replace: true,
                        });
                      }
                    }
                  }}
                  tabIndex={0}
                  role="button"
                  className={clsx(
                    'ring-inset ring-neutral-light-8 hover:bg-neutral-light-3',
                    {
                      'z-10 bg-neutral-light-3':
                        search.member === row.original.data.email,
                    }
                  )}
                >
                  {children}
                </DrawerTrigger>
              );
            }}
          />
        </div>

        <DrawerPortal>
          <DrawerContent className="w-full max-w-[530px]" ref={modalRef}>
            <MemberDrawer data={activeMemberData} />
          </DrawerContent>
        </DrawerPortal>
      </Drawer>

      <ul
        data-cy="members-list-mobile"
        className="border-t border-gray-100 lm:hidden"
      >
        {table.getRowModel().rows.map((row) => (
          // We use the table's row state because it is already filtered and sorted
          <li
            data-cy={row.id}
            key={row.id}
            className="cursor-pointer border-b border-gray-100 py-12 hover:bg-neutral-light-3"
            onClick={() => {
              if (search.member !== row.original.data.email) {
                void navigate({
                  search: { member: row.original.data.email },
                  replace: true,
                });
              } else {
                void navigate({ search: { member: undefined }, replace: true });
              }
            }}
          >
            <div className="flex items-center justify-between gap-16 px-8">
              <div className="flex min-w-0 grow flex-col">
                <Typography variant="body-sm-regular" className="truncate">
                  {fleetMemberRowHelpers.getName(row.original) ||
                    row.original.data.email ||
                    'No name'}
                </Typography>
                <Typography
                  variant="interface-xs-regular"
                  className="text-neutral-dark-1"
                >
                  <TimeSinceNow
                    date={fleetMemberRowHelpers.getLastSeenDate(row.original)}
                  />
                </Typography>
              </div>

              <div className="flex shrink flex-wrap items-center justify-end gap-4">
                {ensureUniqueKeys(
                  fleetMemberRowHelpers.getDevices(row.original),
                  (d) => d.serialNumber
                ).map((d) => (
                  <DeviceIcon key={d.key} device={d} />
                ))}
                {ensureUniqueKeys(
                  fleetMemberRowHelpers.getCompanions(row.original),
                  (c) => c.serialNumber
                ).map((c) => (
                  <DeviceIcon key={c.key} device={c} />
                ))}
                {fleetMemberRowHelpers
                  .getIntegrations(row.original)
                  .map((i) => (
                    <IntegrationIcon
                      className="h-16"
                      key={
                        (i.provider ?? '') +
                        (i.createdAt ?? indexedDB.toString())
                      }
                      provider={i.provider}
                    />
                  ))}
              </div>

              <CaretRight size={20} className="text-neutral-dark-1" />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
