import { MouseEvent, ReactNode, useEffect, useMemo, useState } from 'react';

import { CaretDown, CaretUp, X } from '@phosphor-icons/react';
import { Tag, Typography } from '@remarkable/ark-web';
import { cva } from 'class-variance-authority';
import clsx from 'clsx';
import { useAtom } from 'jotai';
import { CheckCircle, Info, Warning } from 'phosphor-react';

import { HTTPError } from 'src/api/createApiClient.types';
import { IS_DEVELOPMENT } from 'src/config';
import { atomWithSessionStorage } from 'src/utils/atomWithSessionStorage';
import { cn } from 'src/utils/classNamesHelper';
import { UnreachableCodeError } from 'src/utils/unreachableCode';

import { Button } from './Button';

type NotificationVariants = 'error' | 'warning' | 'info' | 'success';

type NotificationError = Error | HTTPError;

type NotificationBoxBaseProps = {
  muted?: boolean;
  onClose?: (e: MouseEvent<HTMLButtonElement>) => void;
  children?: ReactNode;
  useErrorMessage?: boolean;
  className?: string;
};

type NotificationBoxManualProps = NotificationBoxBaseProps & {
  variant: NotificationVariants;
  title: string | ReactNode;
  error?: never;
};

type NotificationBoxWithErrorProps = NotificationBoxBaseProps & {
  variant?: NotificationVariants;
  title?: ReactNode;
  error:
    | NotificationError
    | null
    | undefined
    | (NotificationError | null | undefined)[];
};

type NotificationBoxProps =
  | NotificationBoxManualProps
  | NotificationBoxWithErrorProps;

const notificationBoxVariants = cva('flex rounded md:flex-row', {
  variants: {
    variant: {
      error: 'bg-[#F9DADB] [&>svg]:fill-feedback-red-500',
      warning: 'bg-[#FBEBD7]',
      info: 'bg-neutral-light-3',
      success: 'bg-[#E5EEE3]',
    },
    muted: {
      true: 'flex-row gap-8 bg-transparent',
      false: 'flex-col items-center p-16',
    },
    align: {
      left: 'md:items-start',
    },
  },
  defaultVariants: {
    variant: 'info',
    muted: false,
  },
});

const getIconElement = (variant: NotificationVariants) => {
  switch (variant) {
    case 'error':
      return Warning;
    case 'warning':
    case 'info':
      return Info;
    case 'success':
      return CheckCircle;
    default:
      throw new UnreachableCodeError(variant);
  }
};

export const showErrorBodyAtom = atomWithSessionStorage('showErrorBody', false);
const useShowErrorBody = () => useAtom(showErrorBodyAtom);

export const NotificationBox = ({
  title,
  children,
  variant = 'error',
  muted = false,
  className = '',
  onClose,
  useErrorMessage = false,
  ...props
}: NotificationBoxProps) => {
  const align = children ? 'left' : null;
  const Icon = getIconElement(variant);

  const [showErrorBody, setShowErrorBody] = useShowErrorBody();

  const [responseBody, setResponseBody] = useState<unknown>();
  const [requestBody, setRequestBody] = useState<unknown>();

  const { error: notificationError, ...rest } = props;

  const error = Array.isArray(notificationError)
    ? notificationError.filter(Boolean)[0]
    : notificationError;

  useEffect(() => {
    if (error instanceof HTTPError) {
      if (!error.response.bodyUsed) {
        void error.response.text().then((text) => {
          try {
            setResponseBody(JSON.parse(text));
          } catch {
            setResponseBody(text);
          }
        });
      }

      if (!error.request.bodyUsed) {
        void error.request.text().then((text) => {
          try {
            setRequestBody(JSON.parse(text));
          } catch {
            setRequestBody(text);
          }
        });
      }
    }
  }, [error]);

  const requestUrl = useMemo(() => {
    if (!(error instanceof HTTPError)) {
      return null;
    }

    const url = new URL(error.request.url);

    return {
      path: url.pathname + url.search + url.hash,
      domain: url.origin,
    };
  }, [error]);

  if ('error' in props && !error) {
    // Error prop is in use, but no error has occured
    return null;
  }

  return (
    <div
      data-cy="notification-box"
      {...rest}
      className={notificationBoxVariants({ variant, muted, align, className })}
    >
      <div>
        <Icon
          weight={muted ? 'light' : 'regular'}
          className={cn(muted ? 'h-[28px] w-[28px] px-2' : 'h-32 w-32', {
            'text-feedback-red-500': variant === 'error',
            'text-feedback-orange-500': variant === 'warning',
            'text-gray-800': variant === 'info',
            'text-feedback-green-500': variant === 'success',
          })}
        />
      </div>
      <div
        className={clsx(
          'body-sm-regular flex w-full min-w-0 flex-col items-center text-center md:text-left',
          {
            'md:ml-16': !muted,
            'mt-2': muted,
            'md:items-start': !!children || error,
          }
        )}
      >
        <div className="my-8 flex w-full items-center justify-center gap-16 md:my-0 md:justify-between">
          <Typography
            variant={muted ? 'body-md-regular' : 'body-md-bold'}
            className={muted ? 'text-left' : ''}
            as="h2"
          >
            {title ?? 'Something went wrong'}
          </Typography>
          {onClose && (
            <Button
              data-cy="close-notification"
              variant="tertiary-neutral"
              onClick={onClose}
              size="small"
            >
              <X />
            </Button>
          )}
        </div>
        {children ??
          (!!props.error && (
            <span>
              {useErrorMessage && error instanceof Error
                ? error.message
                : 'Try again later or contact support if the problem persists.'}
            </span>
          ))}

        <div className="relative flex w-full justify-end sm:mt-0">
          <Button
            size="small"
            variant="tertiary-neutral"
            className="bottom-0 right-0 text-neutral-dark-1 sm:absolute"
            onClick={() => setShowErrorBody(!showErrorBody)}
          >
            {showErrorBody ? <CaretUp /> : <CaretDown />}{' '}
            <span>{showErrorBody ? 'Hide' : 'Details'}</span>
          </Button>
        </div>

        {IS_DEVELOPMENT && error && showErrorBody && (
          <div className="mt-16 w-full border-none bg-neutral-light-1/50 p-16 text-left">
            <Typography variant="interface-sm-caps-medium" className="muted">
              {error.name}
            </Typography>
            <div>
              {error.message && (
                <Typography variant="interface-xs">{error.message}</Typography>
              )}
              {error instanceof HTTPError ? (
                <div className="flex flex-col gap-16">
                  <div>
                    <div className="flex justify-between gap-16 py-8">
                      <Typography variant="interface-lg-semibold">
                        Response
                      </Typography>
                      <Tag variant="themed-filled" className="bg-error-light">
                        Status: {error.response.status}{' '}
                        {error.response.statusText}
                      </Tag>
                    </div>

                    <div className="overflow-x-scroll">
                      <Typography variant="interface-md">
                        <span className="text-muted">
                          {error.request.method}
                        </span>{' '}
                        {requestUrl?.path}
                      </Typography>
                      <Typography variant="interface-xs">
                        {requestUrl?.domain}
                      </Typography>
                    </div>

                    <div className="overflow-x-scroll">
                      <pre>{JSON.stringify(responseBody, null, 2)}</pre>
                    </div>
                  </div>
                  <div>
                    <Typography
                      variant="interface-lg-semibold"
                      className="my-8"
                    >
                      Request
                    </Typography>

                    <Typography variant="interface-sm-caps-medium">
                      Body
                    </Typography>

                    <pre>
                      {requestBody ? JSON.stringify(requestBody, null, 2) : '-'}
                    </pre>
                  </div>
                </div>
              ) : (
                <>
                  {error.cause && error.cause instanceof Error && (
                    <pre>
                      Cause: {error.cause.name}: {error.cause.message}
                    </pre>
                  )}
                  <pre className="overflow-scroll">{error.stack}</pre>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
